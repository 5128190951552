/* eslint-disable react/no-unescaped-entities */
import {
  BuildOutlined,
  DollarCircleOutlined,
  GroupOutlined,
  HeartFilled,
  HeartOutlined,
  SlackOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import Link from 'next/link';
import styles from '@buddieshr/styles/constants.module.scss';
import Image from 'next/image';

export const LANGUAGES = ['en'];

export const CRISP_WEBSITE_ID_PERS = '14703f10-388b-4ace-8e47-9122ebc57196';

export const G2_LINK = 'https://www.g2.com/products/buddieshr/reviews';

export const LOCAL_STORAGE = {
  REF: 'buddies_ref',
  HAS_SEEN_PRICING: 'HAS_SEEN_PRICING',
};

export const MESSAGE_TYPES = {
  v1: 'V1',
  v2: 'V2',
};

export const SCOPE_CHOICES = {
  WORKSPACE: 'WORKSPACE',
  CHANNEL: 'CHANNEL',
};

export const CELEBRATION_TYPES = {
  BIRTHDAYS: 'BIRTHDAYS',
  WORK_ANNIVERSARIES: 'WORK_ANNIVERSARIES',
};

export const PROFILE_PICS = [
  '/img/apps/billy/pp/ryan.jpeg',
  '/img/apps/billy/pp/sofia.jpeg',
  '/img/apps/billy/pp/peter.jpeg',
  '/img/apps/billy/pp/alex.jpeg',
];

export const SUBSCRIBE_URL_BUDDIES = 'https://api.buddieshr.com/subscribe';

export const FAQ = [
  {
    title: 'faq1Title',
    slug: 'can-i-choose-any-app',
    content: 'faq1Content',
  },
  {
    title: 'faq2Title',
    slug: 'what-happens-cancel-only-one-app',
    content: 'faq2Content',
  },
  {
    title: 'faq3Title',
    slug: 'payment-for-all-members-or-only-users',
    content: 'faq3Content',
  },
  {
    title: 'faq4Title',
    slug: 'can-i-get-free-trial',
    content: 'faq4Content',
  },
];

export const SHOW_MARKETING_BANNER = true;

export const GA_MEASUREMENT_ID = 'G-8XRSPTFBN0';

export const getDescriptionFromID = (id, refSource) => {
  switch (id) {
    case 'alfy':
      return (
        <div>
          <div>
            {
              'Build relationships with colleagues thanks to weekly peer-to-peer coffee matching. Every week 📅, employees are being matched together ⚡️ to have a short coffee break ☕️ together. Pair people based on their team, level, seniority, location, hobbies or more.'
            }
          </div>
          <br />
          <Link href={`/alfy`} alt="donut random coffee for Slack">
            <Button type="primary" size="large">
              Learn more about Alfy Matching
            </Button>
          </Link>
        </div>
      );
    case 'billy':
      return (
        <div>
          <div>
            {
              "Automating birthdays is the most simple and quickest way to increase employee recognition. Working remotely and having a distributed team has a lot of advantages, but social events and celebrations need a little more help. We're here to fill the gap and build the Future of work together."
            }
          </div>
          <br />
          <Link href={`/billy`} alt="birthday bot for slack and teams">
            <Button type="primary" size="large">
              Learn more about Billy Birthday
            </Button>
          </Link>
        </div>
      );
    case 'clappy':
      return (
        <div>
          <div>
            {
              'Facilitate authentic and meaningful appreciation among your employees while bringing your company values to life.'
            }
            <br />
            Make sure kudos are sent regularly and team members feel appreciated
            for their contribution to the company.
          </div>
          <br />
          <Link href={`/clappy`} alt="best employee recognition software">
            <Button type="primary" size="large">
              Learn more about Clappy Kudos
            </Button>
          </Link>
        </div>
      );
    case 'pulsy':
      return (
        <div>
          <div>
            {
              "This is a corner stone in every company. Measuring employees engagement is necessary and Pulsy makes sure you get the best response rate. Measure eNPS and engagement. Listen to employee's voice. Broadcast surveys and get answers fast."
            }
          </div>
          <br />
          <Link href={`/pulsy`} alt="measure enps and engagement in slack">
            <Button type="primary" size="large">
              Learn more about Pulsy Survey
            </Button>
          </Link>
        </div>
      );
    case 'linky':
      return (
        <div>
          <div>
            {
              'Building an Org Chart is no fun, except if you have Linky installed. Wether you choose to bulk import the data or you put Linky on auto-pilot, the Org Chart will be populated within a very short period of time and we will make sure it stays up to date from job changes, departures and newcomers. '
            }
          </div>
          <br />
          <Link href={`/linky`} alt="org chart and employee directory">
            <Button type="primary" size="large">
              Learn more about Linky Directory
            </Button>
          </Link>
        </div>
      );
  }
};

export const COMPETITOR_IMG = {
  organice: {
    img: 'https://avatars.slack-edge.com/2022-10-11/4219206805217_f1247b5e57f3a290e492_512.png',
  },
  birthdaybot: {
    img: 'https://avatars.slack-edge.com/2022-09-02/4028655733237_69a8eae2fd9c7ee3b6b9_48.png',
  },
  celebrations: {
    img: 'https://avatars.slack-edge.com/2023-05-17/5307136771632_23fc04d1c85ee699a7ab_48.png',
  },
  celebrations_by_airsp: {
    img: 'https://avatars.slack-edge.com/2023-05-16/5288408943857_dd9163fa2fbd9025ef1a_48.png',
  },
  heytaco: {
    img: 'https://avatars.slack-edge.com/2021-06-07/2144814816035_94f18513ec97458e7bb0_48.png',
  },
  matterbot: {
    img: 'https://avatars.slack-edge.com/2022-11-01/4299030558022_e2a5fae587705849f775_48.png',
  },
  bonusly: {
    img: 'https://avatars.slack-edge.com/2021-10-28/2661929214659_f41700f65ac4e426b25e_48.png',
  },
  shoutouts_by_airspeed: {
    img: 'https://avatars.slack-edge.com/2023-04-13/5127211793904_7088033763605e48a27a_48.png',
  },
  evergreen: {
    img: 'https://avatars.slack-edge.com/2023-05-30/5327792447847_3bb43fe865ece4bcc5e5_48.png',
  },
  engagewith: {
    img: 'https://avatars.slack-edge.com/2023-03-30/5022544196855_a000bed0e301f9f64919_48.jpg',
  },
  kudosing: {
    img: 'https://avatars.slack-edge.com/2021-08-13/2391014510897_40fec22846e73c878042_48.png',
  },
  kudosbot: {
    img: 'https://avatars.slack-edge.com/2023-02-06/4760731044611_ecb27ff0dc6bd0db7a20_48.png',
  },
  culturebot: {
    img: 'https://avatars.slack-edge.com/2022-05-16/3515634159655_6e18d0e2e4fda972f49e_48.png',
  },
  praisely: {
    img: 'https://avatars.slack-edge.com/2023-04-15/5139623395664_0649280d5251b2df33ed_48.png',
  },
  karma: {
    img: 'https://avatars.slack-edge.com/2022-05-27/3589622557075_b34dc94138c453d6c88f_48.png',
  },
  dankon: {
    img: 'https://avatars.slack-edge.com/2023-05-21/5298925769186_018ddd00b8a34f2e09a3_48.jpg',
  },
  imfrank: {
    img: 'https://avatars.slack-edge.com/2023-08-14/5735683573365_570b714f197102e08a1f_48.jpg',
  },
  kudos_by_deel: {
    img: 'https://avatars.slack-edge.com/2023-08-28/5813816642835_8005c4ff994e070d5c9f_48.png',
  },
  kudos3: {
    img: 'https://avatars.slack-edge.com/2023-12-01/6278380443075_dbbca51be8a8a0f9c802_48.png',
  },
  apprecibot: {
    img: 'https://avatars.slack-edge.com/2023-10-20/6067627830949_1be8fbc1ad4c65b1d34d_48.png',
  },
  hoyaapp: {
    img: 'https://avatars.slack-edge.com/2023-09-21/5924356618406_e0b79e8279dc95e00c08_48.png',
  },
  donut: {
    img: 'https://avatars.slack-edge.com/2022-09-09/4060168159219_5a8b151d919e04a8dd33_48.png',
  },
  lunchroulette: {
    img: 'https://avatars.slack-edge.com/2021-08-02/2335227334581_969f0b24917e19128476_48.png',
  },
  coffee_chat: {
    img: 'https://avatars.slack-edge.com/2023-11-24/6246729434258_4e38df24419204ff597e_48.png',
  },
  coffeetalk_by_airspee: {
    img: 'https://avatars.slack-edge.com/2023-07-14/5593172258401_6337014d649325db1660_48.png',
  },
  randomcoffee: {
    img: 'https://avatars.slack-edge.com/2022-10-11/4204093575171_db5534cd827feec7181a_48.png',
  },
  randomcoffees: {
    img: 'https://avatars.slack-edge.com/2022-02-08/3090082092641_b4b3c5d53252fb154b86_48.png',
  },
  catchup: {
    img: 'https://avatars.slack-edge.com/2022-01-07/2921791993586_38c8c8c38c5c6fd60799_48.jpg',
  },
  spotbot: {
    img: 'https://avatars.slack-edge.com/2023-01-22/4665258584535_9ee4c129bc9ee8cd88a2_48.jpg',
  },
  pringa: {
    img: 'https://avatars.slack-edge.com/2023-08-08/5722632410305_37358b55579ff4f7bce4_48.png',
  },
  dates_celebration_bot: {
    img: 'https://avatars.slack-edge.com/2023-10-30/6137171691568_f8490aa6ddfae287ce84_48.png',
  },
  surveys: {
    img: 'https://avatars.slack-edge.com/2022-07-26/3847384633893_bb2335a5848131e9b6d5_48.png',
  },
  surveyapp: {
    img: 'https://avatars.slack-edge.com/2023-06-07/5374890169447_2f8a296dec1295e15f16_48.png',
  },
  polly: {
    img: 'https://avatars.slack-edge.com/2022-05-16/3525831951110_93a5fc5000afac75d4b8_48.png',
  },
  simplepoll: {
    img: 'https://avatars.slack-edge.com/2022-08-14/3962301693312_3a4f2a76bb17bbdfc635_48.png',
  },
  'pulse-surveys-roots': {
    img: 'https://avatars.slack-edge.com/2023-01-24/4677485294327_f8ef6368d31167f5f812_48.png\n',
  },
  standuply: {
    img: 'https://avatars.slack-edge.com/2019-01-22/530635819106_1a9edef80a671c6f0cf0_512.jpg',
  },
  dailybot: {
    img: 'https://avatars.slack-edge.com/2020-06-25/1191052137223_365bc47e7c3d38ed587f_512.jpg',
  },
  geekbot: {
    img: 'https://avatars.slack-edge.com/2018-10-18/458684668640_b6e0feb5a3be66d465f4_512.png',
  },
};

export const SECURITY_POLICIES = [
  'Acceptable Use Policy',
  'Access Control and Termination Policy',
  'Business Continuity and Disaster Recovery Plan',
  'Change Management Policy',
  'Code of Conduct',
  'Configuration and Asset Management Policy',
  'Data Classification Policy',
  'Data Retention and Disposal Policy',
  'Encryption and Key Management Policy',
  'Information Security Policy',
  'Internal Control Policy',
  'Network Security Policy',
  'Performance Review Policy',
  'Physical Security Policy',
  'Risk Assessment and Treatment Policy',
  'Secure Development Policy',
  'Security Incident Response Plan',
  'Vendor Management Policy',
  'Vulnerability and Patch Management Policy',
];

export const BENEFITS_BUDDIESHR = [
  {
    icon: <BuildOutlined />,
    title: 'Modular... and all in one',
    description:
      'Get only what you need. Most softwares have a lot of things you don’t want. With buddies, choose only relevant apps for your usage.',
  },
  {
    icon: <SlackOutlined />,
    title: '100% in slack',
    description:
      "This is where the culture happens. We know you don't want to have, again, another app to signup to. Each module is an app for Slack. All apps are configurable independently (each one can have separate channels and notification settings)",
  },
  {
    icon: <DollarCircleOutlined />,
    title: 'Best price for value',
    description: (
      <div>
        Everyone should be able to afford these culture helpers. We've made our
        pricing reflect that. Not only each app is cheap, we also have an{' '}
        <Link
          href="/pricing"
          style={{ color: '#0015ef', textDecoration: 'underline' }}
        >
          amazing offer
        </Link>{' '}
        for all apps.
      </div>
    ),
  },
  {
    icon: <HeartOutlined />,
    title: 'Built by HRs for HRs',
    description: (
      <div>
        A team of passionate HR experts is helping build BuddiesHR apps. Check
        out our&nbsp;
        <Link
          href="/advisors"
          style={{ color: '#0015ef', textDecoration: 'underline' }}
        >
          Advisory Board
        </Link>
        .
      </div>
    ),
  },

  {
    icon: <DollarCircleOutlined />,
    title: 'Straight signup process',
    description: (
      <div>
        Simplicity and efficiency rank high in our processes. Here is how it
        looks to install BuddiesHR apps:
        <div className={styles.processWrapper}>
          <div className={styles.processBuddies}>
            <h4 className={styles.greenTitle}>BuddiesHR (2 minutes)</h4>
            <ul className={styles.greenList}>
              <li>Install</li>
              <li>
                Set it up <br />
                <span style={{ color: '#5f5f5f' }}>
                  (video tutorials, live chat and documentation included)
                </span>
              </li>
              <li>Done</li>
            </ul>
            <div
              style={{ textAlign: 'center', margin: 'auto', marginTop: '12px' }}
            >
              <div>
                <Image
                  src="/img/drop.png"
                  alt="simple ux"
                  width={320}
                  height={180}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <Link href="get-started">
                <Button
                  type="primary"
                  size="large"
                  style={{
                    width: '200px',
                    textAlign: 'center',
                    margin: 'auto',
                    marginTop: 12,
                  }}
                >
                  Get started now
                </Button>
              </Link>
            </div>
          </div>
          <div className={styles.processCompetition}>
            <h4 className={styles.redTitle}>Competitor (5 months)</h4>
            <ul className={styles.redList}>
              <li>
                <span className={styles.redIcon}>😵‍💫</span>Book a demo
              </li>
              <li>
                <span className={styles.redIcon}>😬</span>Meet an SDR
              </li>
              <li>
                <span className={styles.redIcon}>😲</span>Schedule a meeting
                with an other sales rep
              </li>
              <li>
                <span className={styles.redIcon}>🤑</span>Get a quote{' '}
              </li>
              <li>
                <span className={styles.redIcon}>🥵</span>Back and forth for
                questions
              </li>
              <li>
                <span className={styles.redIcon}>🥶</span>Back and forth with
                Finance
              </li>
              <li>
                <span className={styles.redIcon}>🤯</span>Close a multi-year
                deal
              </li>
              <li>
                <span className={styles.redIcon}>🥴</span>Get a CSM assigned
              </li>
              <li>
                <span className={styles.redIcon}>😶‍🌫️</span>Schedule an onboarding
                session
              </li>
              <li>
                <span className={styles.redIcon}>😵</span>Learn a crazy complex
                UI
              </li>
              <li>...</li>
            </ul>
          </div>
        </div>
      </div>
    ),
  },
];

export const PRICES = {
  v1: {
    price: 1,
    discountedPrice: 0.5,
    discount: '-50%',
  },
};

export const NULL_STRING = 'null';

export const VERSUS_DONUT = [
  {
    name: 'freeTrial',
    self: false,
    alfy: true,
  },
  {
    name: 'pricePerUser',
    self: 'pricedonut',
    alfy: 'pricealfy',
  },
  {
    name: 'unlimitedIntros',
    self: true,
    alfy: true,
  },
  {
    name: 'advancedMatchingOptions',
    self: false,
    alfy: true,
  },
  {
    name: 'chooseGroupSize',
    self: true,
    alfy: true,
  },
  {
    name: 'chooseMatchingFrequency',
    self: true,
    alfy: true,
  },
  {
    name: 'g2rate',
    self: '4.5/5',
    alfy: '4.9/5',
  },
];
export const VERSUS_DONUT_BUDDIESHR = [
  {
    name: 'freeTrial',
    self: false,
    buddieshr: true,
  },
  {
    name: 'pricePerUser',
    self: 'pricedonut',
    buddieshr: 'pricealfy',
  },
  {
    name: 'birthdayWorkAnniversaries',
    self: true,
    buddieshr: true,
  },
  {
    name: 'coffeeMatching',
    self: true,
    buddieshr: true,
  },
  {
    name: 'kudosRecognition',
    self: true,
    buddieshr: true,
  },
  {
    name: 'SurveysPollsFeature',
    self: false,
    buddieshr: true,
  },
  {
    name: 'orgChartFeature',
    self: false,
    buddieshr: true,
  },
  {
    name: 'standupFeature',
    self: false,
    buddieshr: true,
  },
  {
    name: 'g2rate',
    self: '4.5/5',
    buddieshr: '4.9/5',
  },
];
export const VERSUS_AIRSPEED_BILLY = [
  {
    name: 'birthdayFeature',
    self: true,
    billy: true,
  },
  {
    name: 'multiLang',
    self: false,
    billy: true,
  },
  {
    name: 'upcomingFeature',
    self: false,
    billy: true,
  },
  {
    name: 'optoutOption',
    self: true,
    billy: true,
  },
  {
    name: 'weekendFeature',
    self: false,
    billy: true,
  },
  {
    name: 'autocollectFeature',
    self: true,
    billy: true,
  },
  {
    name: 'importFeature',
    self: true,
    billy: true,
  },
  {
    name: 'privateFeature',
    self: false,
    billy: true,
  },
  {
    name: 'multiFeature',
    self: false,
    billy: true,
  },
];
export const VERSUS_AIRSPEED = [
  {
    name: 'freeTrial',
    self: true,
    alfy: true,
  },
  {
    name: 'pricePerUser',
    self: 'priceairspeed',
    alfy: 'pricealfy',
  },
  {
    name: 'unlimitedIntros',
    self: true,
    alfy: true,
  },
  {
    name: 'advancedMatchingOptions',
    self: false,
    alfy: true,
  },
  {
    name: 'chooseGroupSize',
    self: false,
    alfy: true,
  },
  {
    name: 'chooseMatchingFrequency',
    self: true,
    alfy: true,
  },
  {
    name: 'g2rate',
    self: '4.8/5',
    alfy: '4.9/5',
  },
];
export const VERSUS_RANDOMCOFFEE = [
  {
    name: 'freeTrial',
    self: true,
    alfy: true,
  },
  {
    name: 'pricePerUser',
    self: 'pricerandom',
    alfy: 'pricealfy',
  },
  {
    name: 'unlimitedIntros',
    self: true,
    alfy: true,
  },
  {
    name: 'advancedMatchingOptions',
    self: true,
    alfy: true,
  },
  {
    name: 'chooseGroupSize',
    self: true,
    alfy: true,
  },
  {
    name: 'chooseMatchingFrequency',
    self: true,
    alfy: true,
  },
  {
    name: 'g2rate',
    self: '-',
    alfy: '4.9/5',
  },
];

export const VERSUS_DONUT_BILLY = [
  {
    name: 'trialPeriod',
    self: 'fourteenDays',
    billy: 'thirtyDays',
  },
  {
    name: 'freeForever',
    self: false,
    billy: true,
  },
  {
    name: 'pricePerUser',
    self: 'priceDonut',
    billy: 'priceBilly',
  },

  {
    name: 'birthdayFeature',
    self: true,
    billy: true,
  },
  {
    name: 'multiLang',
    self: false,
    billy: true,
  },
  {
    name: 'upcomingFeature',
    self: false,
    billy: true,
  },
  {
    name: 'optoutOption',
    self: true,
    billy: true,
  },
  {
    name: 'weekendFeature',
    self: false,
    billy: true,
  },
  {
    name: 'autocollectFeature',
    self: true,
    billy: true,
  },
  {
    name: 'importFeature',
    self: true,
    billy: true,
  },
  {
    name: 'privateFeature',
    self: true,
    billy: true,
  },
  {
    name: 'multiFeature',
    self: true,
    billy: true,
  },
];
export const VERSUS_BIRTHDAYBOT = [
  {
    name: 'trialPeriod',
    self: 'fourteenDays',
    billy: 'thirtyDays',
  },
  {
    name: 'freeForever',
    self: false,
    billy: true,
  },
  {
    name: 'pricePerUser',
    self: 'priceBirthdayBot',
    billy: 'priceBilly',
  },

  {
    name: 'birthdayFeature',
    self: true,
    billy: true,
  },
  {
    name: 'multiLang',
    self: false,
    billy: true,
  },
  {
    name: 'upcomingFeature',
    self: true,
    billy: true,
  },
  {
    name: 'optoutOption',
    self: true,
    billy: true,
  },
  {
    name: 'weekendFeature',
    self: true,
    billy: true,
  },
  {
    name: 'autocollectFeature',
    self: true,
    billy: true,
  },
  {
    name: 'importFeature',
    self: true,
    billy: true,
  },
  {
    name: 'privateFeature',
    self: true,
    billy: true,
  },
  {
    name: 'multiFeature',
    self: true,
    billy: true,
  },
];

export const TESTIMONIALS_BILLY = [
  // { quote: "trustedByQuote", from: "trustedByCustomer" },
  {
    quote: 'quoteKatrina',
    from: 'fromKatrina',
    profilePic: '/img/apps/billy/reviews/katrina.jpg',
  },
  {
    quote: 'quoteAlexandra',
    from: 'fromAlexandra',
    profilePic: '/img/apps/billy/reviews/alexandra.jpg',
  },
  {
    quote: 'quoteSarah',
    from: 'fromSarah',
    profilePic: '/img/apps/billy/reviews/sarah.jpeg',
  },
  {
    quote: 'quoteLorena',
    from: 'fromLorena',
    profilePic: '/img/apps/billy/reviews/lorena.png',
  },
  {
    quote: 'quoteVictor',
    from: 'fromVictor',
    profilePic: '/img/apps/billy/reviews/victor.jpeg',
  },
  {
    quote: 'quoteSofia',
    from: 'fromSofia',
    profilePic: '/img/apps/billy/reviews/sofia.jpeg',
  },
  {
    quote: 'quoteLaura',
    from: 'fromLaura',
    profilePic: '/img/apps/billy/reviews/laura.jpeg',
  },
  {
    quote: 'quoteEfe',
    from: 'fromEfe',
    profilePic: '/img/apps/billy/reviews/efe.png',
  },
];

export const ALL_TESTIMONIALS_BILLY = [
  {
    quote: 'quotemary',
    from: 'frommary',
  },
  {
    quote: 'quotejulia',
    from: 'fromjulia',
  },
  {
    quote: 'quotetoma',
    from: 'fromtoma',
  },
  ...TESTIMONIALS_BILLY,
];

export const MICROSOFT_TEAMS_LINK =
  'https://appsource.microsoft.com/en-us/product/teams-app/WA200005617';

export const FAQ_BILLY = [
  {
    title: 'faq1Title',
    slug: 'how-to-move-billy-birthday-to-another-channel',
    content: 'faq1Content',
  },
  {
    title: 'faq2Title',
    slug: 'how-to-change-the-time-when-the-celebrations-are-posted',
    content: 'faq2Content',
  },
  {
    title: 'faq3Title',
    slug: 'how-do-i-change-my-birthday',
    content: 'faq3Content',
  },
  {
    title: 'faq4Title',
    slug: 'how-do-i-change-my-work-anniversary',
    content: 'faq4Content',
  },
  {
    title: 'faq5Title',
    slug: 'how-to-collect-birth-dates-automatically',
    content: 'faq5Content',
  },
  {
    title: 'faq6Title',
    slug: 'how-to-import-birthdays-via-an-excel-spreadsheet',
    content: 'faq6Content',
  },
  {
    title: 'faq7Title',
    slug: 'how-to-add-new-celebration-messages',
    content: 'faq7Content',
  },
  {
    title: 'faq8Title',
    slug: 'how-much-does-billy-birthday-costs',
    content: 'faq8Content',
  },
  {
    title: 'faq9Title',
    slug: 'how-billy-counts-number-users',
    content: 'faq9Content',
  },
  {
    title: 'faq10Title',
    slug: 'how-calculate-fee-when-users-join-or-leave',
    content: 'faq10Content',
  },
  {
    title: 'faqInvoice',
    slug: 'how-find-invoices',
    content: 'faqInvoiceContent',
  },
  {
    title: 'faqRename',
    slug: 'how-rename-birthday-bot',
    content: 'faqRenameContent',
  },
  {
    title: 'faqSubteam',
    slug: 'how-use-billy-subteam',
    content: 'faqSubteamContent',
  },
];
export const ORIGINS = {
  G2: 'G2',
  EMAIL: 'EMAIL',
};
export const LOVE = [
  {
    content:
      "I am currently using Billy and its amazing, I don't have to remember the birthday's and work anniversaries of my team. Billy does it. Great bot.",
    profilePicture: '/img/love/arti.jpg',
    name: 'Arti Shishodia',
    date: '11-13-2024',
    origin: ORIGINS.EMAIL,
    role: 'Team Lead',
    apps: ['billy'],
    companyName: '1West Finance',
  },
  {
    content:
      "Our experience with Billy has been great so far. It's been particularly effective in getting more team interaction and congratulations among coworkers. This feature has helped boost team morale and foster a positive work environment.",
    profilePicture: '/img/love/c.png',
    name: 'Carlos A.',
    date: '10-31-2024',
    origin: ORIGINS.EMAIL,
    role: 'Pediatrics Expert',
    apps: ['billy'],
  },
  {
    content:
      "Now I am at peace as I don’t have to keep a record of the team's birthday. Wishing them their day is significant for keeping the team motivated. I will recommend Billy to my friends as well.",
    profilePicture: '/img/love/z.png',
    name: 'Zorain Z.',
    date: '10-04-2024',
    origin: ORIGINS.EMAIL,
    role: 'Co-Founder & Managing Partner',
    apps: ['billy'],
  },
  {
    content:
      "Helpful tools that have streamlined certain HR processes and improved team collaboration! Alfy- It's nice we can select a time that suits. No complaints, just a feedback- It would be great if we could download poll results directly from Pulsy for even more convenience.",
    profilePicture: '/img/love/a.png',
    name: 'Anonymous',
    date: '04-11-2024',
    origin: ORIGINS.G2,
    role: 'CPO',
    apps: ['pulsy', 'billy', 'alfy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9529440',
  },
  {
    content:
      'What stands out with BuddiesHR is the blend of recognition, networking, and feedback seamlessly integrated into our daily Slack interactions. ',
    profilePicture: '/img/love/m.png',
    name: 'Melissa B.',
    date: '04-18-2024',
    origin: ORIGINS.G2,
    role: 'HR Partner',
    apps: ['pulsy', 'clappy', 'alfy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9553578',
  },
  {
    content:
      'Pulsy offers a seamless experience in filling out HR surveys, ensuring our voices are heard.',
    // profilePicture: '/img/love/user.png',
    name: 'Philip H.',
    date: '05-16-2024',
    origin: ORIGINS.G2,
    role: 'Human Resource',
    apps: ['pulsy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9542894',
  },
  {
    content: "I'm genuinely impressed by BuddiesHR's Billy and Pulsy apps.",
    profilePicture: '/img/love/a.png',
    name: 'Albert B.',
    date: '05-17-2024',
    origin: ORIGINS.G2,
    role: 'Human Resource',
    apps: ['billy', 'pulsy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9665316',
    favorite: true,
  },
  {
    content:
      "What I truly appreciate about BuddiesHR's suite, specifically Pulsy, Linky, and Billy, is how each app seamlessly integrates into our daily Slack use. ",
    profilePicture: '/img/love/user.png',
    name: 'Clifford S.',
    date: '05-16-2024',
    origin: ORIGINS.G2,
    role: 'Human Resource',
    apps: ['pulsy', 'linky', 'billy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9663769',
  },
  {
    content:
      "Alfy has transformed our coffee breaks into an exciting opportunity to meet new colleagues, while Clappy has made recognizing peers' achievements a delightful habit. The only hiccup I've encountered is with Clappy. If there aren't colleagues I feel moved to recognize in a given week, I lose my claps as they unfortunately don't accumulate. It'd be great if they could roll over to the next week.",
    profilePicture: '/img/love/user.png',
    name: 'Megan W.',
    date: '04-25-2024',
    origin: ORIGINS.G2,
    role: 'HR',
    apps: ['alfy', 'clappy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9575043',
  },
  {
    content:
      'Pulsy’s survey system is streamlined and user-friendly, encouraging honest and constructive feedback.',
    // profilePicture: '/img/love/user.png',
    name: 'Tony H.',
    date: '05-13-2024',
    origin: ORIGINS.G2,
    role: 'HR Director',
    apps: ['pulsy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9642601',
  },
  {
    content:
      'What stands out for me is the fun and engagement these add-ons bring to our company culture. Alfy breaks the ice by pairing colleagues for coffee chats and Clappy, my personal favorite, enables a culture of recognition and appreciation among peers.',
    profilePicture: '/img/love/r.png',
    name: 'Robert S.',
    date: '04-23-2024',
    origin: ORIGINS.G2,
    role: 'Head of People & Culture',
    apps: ['alfy', 'billy', 'clappy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9567741',
    favorite: true,
  },
  {
    content:
      " Clappy enhances our team's dynamic by encouraging peer-to-peer recognition. The gamification aspect makes it fun and engaging to give kudos.",
    profilePicture: '/img/love/user.png',
    name: 'William S.',
    date: '04-16-2024',
    origin: ORIGINS.G2,
    role: 'Head of People',
    apps: ['clappy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9545517',
  },
  {
    content:
      'Alfy, Clappy, Billy, and Pulsy, seamlessly blends into our daily work life.',
    profilePicture: '/img/love/user.png',
    name: 'Nancy H.',
    date: '04-24-2024',
    origin: ORIGINS.G2,
    role: 'Human Resources',
    apps: ['alfy', 'clappy', 'billy', 'pulsy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9569220',
  },
  {
    content:
      'Alfy, our random coffee chat bot, is a fantastic way to network internally. Linky keeps our org-chart updates effortless and Clappy encourages peer recognition 😍',
    profilePicture: '/img/love/user.png',
    name: 'Harry F.',
    date: '05-13-2024',
    origin: ORIGINS.G2,
    role: 'HRBP',
    apps: ['alfy', 'linky', 'clappy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9644659',
  },
  {
    content:
      'Alfy encourages unexpected yet meaningful connections, and Pulsy simplifies feedback. Together, they enhance our workplace experience.',
    profilePicture: '/img/love/r.png',
    name: 'Roger T.',
    date: '04-23-2024',
    origin: ORIGINS.G2,
    role: 'HR',
    apps: ['alfy', 'pulsy'],
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9563413',
  },
  {
    content:
      "We love it. It's super useful to never forget team members' birthdays!",
    profilePicture: '/img/love/marina.png',
    name: 'Marina Álvaro Castejón ',
    date: '09-25-2024',
    origin: ORIGINS.EMAIL,
    role: 'Head of Marketing',
    apps: ['billy'],
  },
  {
    content:
      'Our experience has been great! Billy help us to not forget any birthday of the team! with the possibility of assigning a time to reminder the b-day, let us order de cake in time for eat with the coffee',
    profilePicture: '/img/love/a.png',
    name: 'Andrea Cortés AGRANS ',
    date: '09-13-2024',
    origin: ORIGINS.EMAIL,
    role: 'Project leader',
    apps: ['billy'],
  },
  {
    content:
      "Billy the Birthday Bot is one of the hardest working members of the team! It's is a great tool that is super easy to set up and 'just works'!",
    profilePicture: '/img/love/ali.png',
    name: 'Âli Okumuşoğlu',
    date: '08-07-2024',
    origin: ORIGINS.G2,
    role: 'Communication Consultant & Founder',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9973008',
    apps: ['billy'],
  },
  {
    content:
      "Billy's been impressive so far. We're a small team yet we tend to miss out on important member dates, good thing Billy takes care of it for us. It's a Bot but it sparks the humanness in our virtual team.",
    profilePicture: '/img/love/user.png',
    name: 'Rieena Piquero',
    date: '09-05-2024',
    origin: ORIGINS.EMAIL,
    role: 'Financial Controller',
    apps: ['billy'],
  },
  {
    content:
      'Appreciate it being so user friendly and the messages having a nice tone of voice. So yeah, happy with it!',
    profilePicture: '/img/love/melody.png',
    name: 'Melody Ebbe',
    date: '08-14-2024',
    origin: ORIGINS.EMAIL,
    role: 'Office Manager',
    apps: ['billy'],
  },
  {
    content:
      'We really like using Billy for Birthdays! We like the copy suggestions and we also like the ability to edit/customize our own messages for birthday shout outs.',
    profilePicture: '/img/love/melissa.jpg',
    name: 'Melissa Tapia',
    date: '08-07-2024',
    origin: ORIGINS.EMAIL,
    role: 'Head of Operations & People',
    apps: ['billy'],
    favorite: true,
    companyLogo: (
      <Image
        src={'/img/love/companies/beekmansocial.com.svg'}
        width={100}
        height={50}
        style={{ height: 'auto' }}
        alt={'logo beekmansocial'}
      />
    ),
  },
  {
    content:
      "We're finding the birthday bot really useful to remind team members of birthdays! It's simple and easy to use, which is great. We really like it.",
    profilePicture: '/img/love/user.png',
    name: 'Marielena James',
    date: '08-07-2024',
    origin: ORIGINS.EMAIL,
    role: 'HR',
    apps: ['billy'],
  },
  {
    content: 'The apps are great. We love them.',
    profilePicture: '/img/love/utkarsh.jpg',
    name: 'Utkarsh Singh',
    date: '08-02-2024',
    origin: ORIGINS.EMAIL,
    role: 'Founder - Director Design',
    apps: ['billy', 'clappy', 'linky'],
  },
  {
    content:
      'Billy has really automated our celebrations process and takes the load off my plate so I am really enjoying it from that perspective.',
    profilePicture: '/img/love/k.png',
    name: 'Kristina Lane',
    date: '07-17-2024',
    origin: ORIGINS.EMAIL,
    role: 'EA & People Operations',
    apps: ['billy'],
  },
  {
    content:
      "We're pleased with Billy's simplicity and ease-of use. It's helped improve our work culture!",
    profilePicture: '/img/love/bonner.jpeg',
    name: 'Bonner Odell',
    date: '06-19-2024',
    origin: ORIGINS.EMAIL,
    role: 'Associate Director of Development',
    apps: ['billy'],
  },
  {
    content:
      "I want to share how amazing Billy has been for me. It's made automating our birthday greetings so much simpler, while still allowing me to add a personal touch for our staff.",
    profilePicture: '/img/love/rm.png',
    name: 'Raimiel Dionido',
    date: '06-18-2024',
    origin: ORIGINS.EMAIL,
    role: 'HR Officer',
    apps: ['billy'],
    favorite: true,
    companyLogo: (
      <Image
        src={'/img/love/companies/no-burn.org.svg'}
        width={60}
        height={50}
        style={{ height: 'auto' }}
        alt={'logo no burn'}
      />
    ),
  },
  {
    content:
      'We are using Billy, Clappy & Pulsy for our NGO and so far we are very happy with it.',
    profilePicture: '/img/love/kilian.jpg',
    name: 'Kilian Tranchant',
    date: '06-11-2024',
    origin: ORIGINS.EMAIL,
    role: 'Chair of the Advisory Committee',
    apps: ['billy', 'clappy', 'pulsy'],
  },
  {
    content:
      'Alfy is an excellent tool which my teams likes to use for our random coffee chats.',
    profilePicture: '/img/love/yulia.jpg',
    name: 'Yulia Dureva',
    date: '06-11-2024',
    origin: ORIGINS.EMAIL,
    role: 'Product Analyst',
    apps: ['alfy'],
  },
  {
    content:
      'I like Billy Bot. It helps automate the process and saves my working time. My colleagues also like this format of congratulations. Thank you so much =)',
    profilePicture: '/img/love/adguard.png',
    name: 'Olga Koplyakova',
    date: '05-31-2024',
    origin: ORIGINS.EMAIL,
    role: 'Recruiter',
    apps: ['billy'],
  },
  {
    content:
      "I am happy with how Billy, the Birthday Bot, has been helping us. It's a great tool for remembering work anniversaries and birthdays. We used to forget them, but not anymore thanks to Billy.",
    profilePicture: '/img/love/r.png',
    name: 'Rona Jones',
    date: '05-29-2024',
    origin: ORIGINS.EMAIL,
    role: 'Principal Support Analyst',
    apps: ['billy'],
  },
  {
    content:
      "Billy has been a great addition to our Slack channel and has been a fantastic way to celebrate our sales team's birthdays and anniversaries. In our current application of Billy, I think he works perfectly.",
    profilePicture: '/img/love/m.png',
    name: 'Max Mendoza',
    date: '05-17-2024',
    origin: ORIGINS.EMAIL,
    role: 'Customer Service/Sales',
    apps: ['billy'],
  },
  {
    content:
      'I just wanted to thank you for the new Alfy updates - I really appreciated having a little bit more control over the groups and how the bookings were doing! :) The team is loving the app - please keep me updated on any new features!!',
    profilePicture:
      'https://lh3.googleusercontent.com/cm/AGPWSu-R7uTcj4k-q1ASDvXqnCwllNeMr6SEYl8NrHF95QP86DMPqwYb3NoCKTXs0O5ukgDMpg=s40-p',
    name: 'Fátima Sánchez',
    date: '05-02-2024',
    origin: ORIGINS.EMAIL,
    role: 'Marketing Coordinator',
    apps: ['alfy'],
  },
  {
    content:
      'LOVING it! It is a fantastic tool and took out the stress of fearing missing a b-day or anniversary for the Team on my end. Billy is great 🥳',
    profilePicture: '/img/love/dana.jpg',
    name: 'Dana Pintaric',
    date: '04-03-2024',
    origin: ORIGINS.EMAIL,
    role: 'Office Manager',
    apps: ['billy'],
  },
  {
    content:
      'Billy is proving to be an excellent resource, and we have checked in on a few other things you guys provide that may be useful for our team, culture wise.',
    profilePicture:
      'https://lh3.googleusercontent.com/cm/AGPWSu8PyKZakYf_QHIINkl3YL90J2qgjbouO7Rj3dN2XOmbeulGjTQc84-NnMP3LYkTUtTZ7w=s40-p',
    name: 'Brooke Festa',
    date: '02-19-2024',
    origin: ORIGINS.EMAIL,
    role: 'Head of content',
    apps: ['billy'],
  },
  {
    content:
      "Thanks for following up. We love both Billy and Clappy. They're both great. Clappy especially has been incredible in boosting our team morale and fostering a much more positive work environment.",
    profilePicture:
      'https://lh3.googleusercontent.com/cm/AGPWSu-qNIy-kreFo9ozu62Tw9r7r9kIFshwqGipaiLFKf0ZdcxCL_6xM3Svf-QwGn0AQys0hQ=s40-p',
    name: 'Shoushan Keshishian',
    date: '01-30-2024',
    origin: ORIGINS.EMAIL,
    role: 'Executive Director',
    apps: ['billy', 'clappy'],
  },
  {
    name: 'Mary Vanesyan',
    content:
      'Our team is loving Billy, Alfy & Pulsy!! Thank you for being so creative and keeping it up:)',
    date: '01-25-2024',
    profilePicture: '/img/love/mary.jpg',
    origin: ORIGINS.EMAIL,
    apps: ['billy', 'alfy', 'pulsy'],
    favorite: true,
  },
  {
    content:
      "Billy allowed us to save time and efficiency in celebrating our employees' birthdays and thus creating team cohesion. This is very easy to set up, it's quite intuitive.",
    name: 'Julia Devienne',
    profilePicture: '/img/love/julia.jpg',
    date: '01-12-2024',
    origin: ORIGINS.EMAIL,
    apps: ['billy'],
  },
  {
    content:
      'Amazing Product, that builds team culture! Their products easily integrate into our slack environment making it easy for us to build our teams culture and appreciation for each other. I was able to download the app with just a click of a button',
    name: 'Devin Light',
    date: '05-03-2024',
    profilePicture: '/img/love/devin.jpg',
    role: 'VP Corporate Relations',
    origin: ORIGINS.G2,
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9605017',
    apps: ['billy'],
    favorite: true,
    companyLogo: (
      <Image
        src={'/img/love/companies/jdcwest.com.png'}
        width={100}
        height={50}
        style={{ height: 'auto' }}
        alt={'logo jdcwest.com'}
      />
    ),
  },
  {
    name: 'Alina Opriatova',
    content:
      "Billy is a great way to celebrate our team's birthday and work anniversaries (which is something new) with an easy-to-use interface and settings. Clappy is fantastic for celebrating the most important things - each other.",
    origin: ORIGINS.G2,
    role: 'Selection Coordinator',
    date: '03-19-2024',
    profilePicture: '/img/love/alina.jpg',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9446690',
    apps: ['billy', 'clappy'],
  },
  {
    name: 'Hayley Jayne',
    content:
      'Billy Birthday has been so wonderful for us these last few months; we have been so preoccupied with other aspects of life and business and still desire our culture to grow and recognition to be awarded.',
    origin: ORIGINS.G2,
    role: 'Founder',
    date: '03-07-2024',
    profilePicture: '/img/love/alina.jpg',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9397402',
    apps: ['billy'],
  },
  {
    name: 'Anonymous',
    content:
      "Employees really enjoy the product. It's a great (and easy) way to recognize and celebrate our staff.",
    origin: ORIGINS.G2,
    role: '',
    date: '02-26-2024',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9346572',
    apps: ['billy', 'clappy'],
  },
  {
    name: 'Dani Levin',
    content:
      "Integrating Billy into our Slack workspace was effortless, and he's already become a staple for our team. We are about 300+ daily volunteers strong, so Billy is an incredible asset to our org. It's also worth mentioning that their support team is top notch - within hours of sending my inquiry I had heard back from their team with a solution already in place! I cannot speak highly enough of Billy and the BuddiesHR team!",
    origin: ORIGINS.G2,
    role: 'Director',
    date: '01-06-2024',
    profilePicture: '/img/love/dani.jpg',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-9089609',
    apps: ['billy'],
  },
  {
    link: 'https://www.g2.com/products/buddieshr/reviews/',
    name: 'Alexandra Sohn',
    role: 'Director of Marketing & Public Relations @ Faithful Platform',
    origin: ORIGINS.G2,
    date: '11-30-2023',
    profilePicture: '/img/love/alexandra.jpg',
    content:
      'The worst feeling is interacting with a co-worker all day long and getting home to see on social media that it was actually their birthday. Now that we have Billy, we are all notified first thing in the morning through Slack about our co-workers Birthdays and it has been such a blessing!',
    apps: ['billy'],
  },
  {
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-8700204',
    name: 'Katrina Ross',
    role: 'Executive Director',
    origin: ORIGINS.G2,
    date: '10-05-2023',
    profilePicture: '/img/love/katrina.jpg',
    content:
      'This app makes recognizing the entire team on their special day possible. Previously, birthdays were tracked on a spreadsheet and team members often were forgotten about, especially the part-timers. Bonus points for the customer care team being attentive and timely in their responses!',
    apps: ['billy'],
  },
  {
    name: 'Haendel José Morales Enriquez',
    content:
      'I think Billy Birthday is a great bot that helps us celebrate the birthdays of our employees. It is very fun and creative to receive the messages from Billy and see the different ways he wishes us a happy birthday.',
    role: 'Directeur médical',
    profilePicture: '/img/love/jose.jpg',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-8565890',
    origin: ORIGINS.G2,
    date: '09-05-2023',
    apps: ['billy'],
  },
  {
    name: 'Stephanie David',
    content:
      "We are a 30-man team that works all over the world, and to maintain both work anniversaries and birthdays to maintain and elevate team culture is a lot. Because of Billy Birthday, we have been able to transition our time and effort in organizing everyone's birthday to actually sending each staff member a heartfelt card and gift, making the experience of our work culture so much better!",
    role: 'Operations Generalist',
    profilePicture: '/img/love/stephanie.jpg',
    link: 'https://www.g2.com/products/buddieshr/reviews/buddieshr-review-8133380',
    origin: ORIGINS.G2,
    date: '05-23-2023',
    apps: ['billy'],
  },
  {
    name: 'Sarah Khau',
    content:
      'We are happy to have Billy installed on our Slack, the employees are delighted to receive a little attention on their birthday.',
    role: 'Office Manager @ smallable',
    profilePicture: '/img/love/sarah.jpeg',
    link: 'https://www.g2.com/products/buddieshr',
    origin: ORIGINS.G2,
    date: '05-11-2023',
    apps: ['billy'],
  },
  {
    name: 'Anonymous',
    content:
      "We're absolutely thrilled with Billy. It was incredibly easy to implement, and our entire team adores it.",
    role: 'People and Culture Coordinator @ SafetyWing',
    profilePicture: '/img/love/l.png',
    origin: ORIGINS.EMAIL,
    date: '05-07-2023',
    apps: ['billy'],
  },
  {
    name: 'Victor',
    content:
      "Billy Birthday has been amazing. It's very intuitive and easy to configure my preferences. I had no problems dealing with Billy Birthday and I loved the functionality of uploading an xls file to import birthdays.",
    role: 'Happyness Manager',
    profilePicture: '/img/love/victor.jpeg',
    origin: ORIGINS.EMAIL,
    date: '05-02-2023',
    apps: ['billy'],
  },
  {
    name: 'Sofía Ramírez',
    content:
      "Billy Birthday is fantastic. It helps us keep track of our teammates' birthdays and strengthen our team's social connections. Adding this feature to our Slack channel is a great way to enhance our human relations.",
    role: 'AgFunder',
    profilePicture: '/img/love/sofia.jpeg',
    origin: ORIGINS.EMAIL,
    date: '05-01-2023',
    apps: ['billy'],
  },
  {
    name: 'Laura Winegardner',
    content:
      "I've been trying to find an affordable solution for over a year now. Very appreciative to you and the team!",
    role: 'Senior Employee Experience Manager @ May Mobility',
    profilePicture: '/img/love/laura.jpeg',
    origin: ORIGINS.EMAIL,
    date: '04-25-2023',
    apps: ['billy'],
    favorite: true,
  },
];

export const ANALYTICS_EVENTS = {
  ADD_TO_SLACK: 'ADD_TO_SLACK',
  ADD_TO_SLACK_BILLY: 'ADD_TO_SLACK_BILLY',
  ADD_TO_SLACK_ALFY: 'ADD_TO_SLACK_ALFY',
  ADD_TO_SLACK_CLAPPY: 'ADD_TO_SLACK_CLAPPY',
  ADD_TO_SLACK_PULSY: 'ADD_TO_SLACK_PULSY',
  ADD_TO_SLACK_LINKY: 'ADD_TO_SLACK_LINKY',
  TRY_IT_NOW: 'TRY_IT_NOW',
  VIEW_MORE_MSGS: 'VIEW_MORE_MSGS',
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
  CLOSE_MARKETING_POPIN: 'CLOSE_MARKETING_POPIN',
  LEARNMORE_MARKETING_POPIN: 'LEARNMORE_MARKETING_POPIN',
  WATCH_DEMO: 'WATCH_DEMO',
  SUCCESS: 'SUCCESS',
  SUCCESS_BILLY: 'SUCCESS_BILLY',
  SUCCESS_ALFY: 'SUCCESS_ALFY',
  SUCCESS_CLAPPY: 'SUCCESS_CLAPPY',
  SUCCESS_PULSY: 'SUCCESS_PULSY',
  SUCCESS_LINKY: 'SUCCESS_LINKY',
  GET_STARTED_TURNOVER_CALCULATOR: 'GET_STARTED_TURNOVER_CALCULATOR',
};
