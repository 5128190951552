/* eslint-disable react/no-unescaped-entities */
import {
  ApartmentOutlined,
  CheckSquareOutlined,
  ControlOutlined,
  DashboardOutlined,
  LinkOutlined,
  LikeTwoTone,
  SyncOutlined,
  BulbOutlined,
  CaretRightOutlined,
  CrownOutlined,
  SmileOutlined,
  TeamOutlined,
  LikeOutlined,
  FieldTimeOutlined,
  DeploymentUnitOutlined,
  CompassOutlined,
  FileSyncOutlined,
  SlackSquareOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { Alert, Button, Divider, Modal, Tag } from 'antd';
import Image from 'next/image';
import styles from '@buddieshr/styles/components/constant.module.scss';
import Link from 'next/link';

export const PRODUCTS = [
  {
    subscribeUrl: 'https://api.alfymatching.com/subscribe',
    subscribeDevUrl: 'https://api-dev.alfymatching.com/subscribe',
    price: 1,
    logoUrl: '/img/apps/alfy/logo_without_background.png',
    logoUrlWithBackground: '/img/apps/alfy/logo.png',
    name: 'Alfy',
    lastName: 'Matching',
    fullName: 'Alfy Matching',
    pictureUrl: '/img/apps/coffee-matching-picture.png',
    color: '#ffebb4',
    lighterColor: '#fef1d3',
    id: 'Alfy',
    slug: 'alfy',
    domain: 'alfymatching.com',
    title: 'Connect your employees',
    shortTitle: 'Connect your team for coffee chats',
    shortTitleValue: 'Get everyone to know each other',
    verb: 'Connect',
    shortDescription:
      'Connect your team together, build strong company culture and team bonding.',
    href: 'https://buddieshr.com/alfy',
    localhref: '/alfy',
    installSlackLink: 'https://api.alfymatching.com/slack/install',
    alt: 'connect with team',
    competitors: [
      'donut',
      'lunchroulette',
      'coffee_chat',
      'coffeetalk_by_airspee',
      'randomcoffee',
      'randomcoffees',
      'catchup',
      'spotbot',
      'pringa',
    ],
    competitorAveragePrice: 1.7,
    show: true,
    tagColor: 'gold',
    textColorBackground:
      'linear-gradient(270deg,#5798cd 10.33%,#69055e 34.36%,#8b097d 60.15%,#394da7 84.04%,#3152aa 92.04%,#3974a7)',
    backgroundColor: '#fff9ef',
    benefits: [
      {
        icon: <LikeTwoTone />,
        title: 'Strengthen camaraderie and streamline collaboration',
        description:
          'When people know each other, productivity and collaboration gets to another level. Alfy significantly improves team bonding and global engagement.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Onboard and introduce new hires',
        description:
          'Make sure everyone feels included and instantly part of the team.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Break down silos',
        description:
          'There nothing worst for a company to see isolated teams. Make sure everyone talk to everyone with Alfy.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Find mentors',
        description:
          'On top of having better work relationships, employees can meet colleagues from different levels and therefore find mentors and coaches among them.',
      },
    ],
    getStartedSteps: [
      {
        icon: <LinkOutlined />,
        title: 'Connect your Slack workspace',
        description: (
          <>
            Click Add to Slack, you'll be ask to accept permissions for the app
            and Boom 💥 you're in! Don't worry, at this stage, you did not
            disturb anyone, the app is visible but did not generate any
            notifications. We will tell you when it does.
            <div style={{ marginTop: 12 }}>
              <Alert
                showIcon={true}
                type="info"
                message="You can install safely without disturbing anyone, we will inform you whenever other Slack users may be notified."
              />
            </div>
          </>
        ),
      },
      {
        icon: <ControlOutlined />,
        title: 'Setup channel and add employees',
        description: (
          <>
            Once Alfy is in your Slack, you can set up the channel where matches
            will be posted. To do this, go to Alfy's settings in Slack by
            clicking on its name in the left sidebard then make sure to be in
            the "home" tab. You can also define matching frequency, group size
            etc.
          </>
        ),
      },
      {
        icon: <CheckSquareOutlined />,
        title: "You're all set!",
        description: (
          <>
            Just wait for the next planned matching and contemplate. Please note
            that it will only send messages to the people in the Slack channel
            that you selected.
          </>
        ),
      },
    ],
    features: [
      {
        icon: '🤝',
        name: 'Peer-to-peer matching',
        desc: 'Connect employees for a quick chat.',
      },
      {
        icon: '🧑‍🤝‍🧑',
        name: 'Choose group size',
        desc: 'Group pairs, 3, 4 or 5 people together',
      },
      {
        icon: '🔁',
        name: 'Choose Frequency',
        desc: 'You can either meet weekly, every 2, 3 or 4 weeks.',
      },
      {
        // icon: <Image src="/img/slack.png" alt="slack" width={50} height={50} />,
        icon: (
          <Image src="/img/slack_icon.png" alt="slack" width={50} height={50} />
        ),
        name: 'All in Slack',
        desc: 'No-one wants another app to register. This application is 100% in Slack.',
      },
      {
        icon: '📅',
        name: 'Scheduling assistant',
        desc: (
          <div>
            <div>
              Alfy will help you find a time slots for all participants to meet.{' '}
            </div>
            <Tag
              color={'blue'}
              style={{
                display: 'flex',
                alignItems: 'center',
                border: 'none',
                marginTop: '12px',
              }}
            >
              <Image
                src="/img/gcal.png"
                width={15}
                height={15}
                alt="google calendar"
                style={{ marginRight: '6px' }}
              />
              Google Calendar Integration
            </Tag>
          </div>
        ),
      },
      {
        icon: '💬',
        name: 'Conversation starters',
        desc: (
          <div>
            Alfy suggests ice-breakers to remove awkward moments when meeting
            new people.
            <div
              style={{
                marginTop: '12px',
              }}
            >
              <Button
                type="link"
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  Modal.info({
                    okText: 'Close',
                    width: 530,
                    title: 'Icebreaker example for coffee matching',
                    content: (
                      <div>
                        <Image
                          src="/img/apps/alfy/starters.png"
                          style={{ width: '100%', height: 'auto' }}
                          width={510}
                          height={623}
                          alt="conversation starter for coffee matching alfy"
                        />
                      </div>
                    ),
                  });
                }}
              >
                <CaretRightOutlined /> View Example
              </Button>
            </div>
          </div>
        ),
      },
      {
        icon: '😶‍🌫️',
        name: 'Random matching',
        desc: "Default matching option. Alfy makes sure that people don't meet twice the same person.",
      },
      {
        icon: '🤓',
        name: 'Custom cross team matching',
        desc: 'Choose to match different teams together.',
      },
      {
        icon: '📊',
        name: 'Dashboard',
        desc: 'Visualize sessions (both past and future). Edit matches or redraw them. Get Statistics on usage.',
        isNew: true,
      },
    ],
    featuresPricing: [
      {
        name: 'Peer-to-peer matching',
      },
      {
        name: 'Choose group size',
      },
      {
        name: 'Choose Frequency',
      },
      {
        name: 'Scheduling assistant',
      },
      {
        name: 'Conversation starters',
      },
      {
        name: 'Random matching',
      },
      { name: 'Custom cross team matching' },
      { name: 'Dashboard for session editing and details' },
    ],
    additionnalResources: [
      {
        title: 'Alternative to donut random coffee for slack',
        name: 'Alfy vs Donut',
        href: '/alfy/alternatives/donut',
      },
      {
        title: 'Alternative to Coffee Talk by Airspeed for slack',
        name: 'Alfy vs Coffee Talk by Airspeed',
        href: '/alfy/alternatives/coffeetalk-airspeed',
      },
      {
        title: 'Alternative to random-coffee for slack',
        name: 'Alfy vs Random Coffee',
        href: '/alfy/alternatives/random-coffee',
      },
      {
        title: 'Alternative to shuffl.ai for slack',
        name: 'Alfy vs Shuffl',
        href: '/alfy/alternatives/shuffl',
      },
    ],
  },
  {
    subscribeUrl: 'https://api.billybirthday.com/subscribe',
    subscribeDevUrl: 'https://api-dev.billybirthday.com/subscribe',
    href: 'https://billybirthday.com/',
    localhref: '/billy',
    installSlackLink: 'https://api.billybirthday.com/slack/install',
    price: 1,
    logoUrlWithBackground: '/img/apps/billy/logo.png',
    logoUrl: '/img/apps/billy/logo_without_background.png',
    fullName: 'Billy Birthday',
    id: 'Billy',
    slug: 'billy',
    name: 'Billy',
    lastName: 'Birthday',
    domain: 'billybirthday.com',
    color: '#b3b4ff',
    lighterColor: '#bbc4f6',
    verb: 'Celebrate',
    title: 'Celebrate your team',
    shortTitle: 'Celebrate birthdays and work anniversaries',
    shortTitleValue: 'Celebrate every employee on their special days',
    shortDescription:
      'Celebrate birthdays and work anniversaries together in Slack.',
    pictureUrl: '/img/apps/square_visual.png',
    alt: 'celebrate colleagues',
    competitors: [
      'birthdaybot',
      'celebrations',
      'celebrations_by_airsp',
      'dates_celebration_bot',
    ],
    competitorAveragePrice: 1.9,
    show: true,
    tagColor: 'purple',
    // background: "#b6c2f5a8",
    backgroundColor:
      'linear-gradient(90deg, #ffe0ee .52%, #fae4f6 24.02%, #cfd7f8 72.81%)',
    textColorBackground:
      'linear-gradient(270deg,#5798cd 10.33%,#69055e 34.36%,#8b097d 60.15%,#394da7 84.04%,#3152aa 92.04%,#3974a7)',
    benefits: [
      {
        icon: <CrownOutlined />,
        title: 'Celebrate birthdays together at work',
        description:
          "Have you ever felt bad discovering that you forgot a teammate's birthday few days after the date ? Won't happen again with Billy. Wishing a happy birthday is a simple thing, we make it simple for you to do it.",
      },
      {
        icon: <TeamOutlined />,
        title: 'Increase employee engagement and team cohesion',
        description:
          'The best effort/impact ratio you can find to increase employee engagement and recognition is to automate birthday and anniversary celebrations. It is cross cultural, cross-religious, cross-generation, cross-timezone etc. And it makes people genuinely happy to receive some positive vibes during this special day.',
      },
      {
        icon: <LinkOutlined />,
        title: 'Distributed teams have never been closer',
        description:
          "The way we work has changed. Working remotely and having a distributed team has a lot of advantages, but social events and celebrations need a little more help. We're here to fill the gap and build the Future of work together.",
      },
      {
        icon: <SmileOutlined />,
        title: 'Bring some fun into everyday routine',
        description:
          'The celebration messages from Billy really bring a fresh wave into every day work routine. We make sure to have the best copywriting to provide fun yet professional wishes.',
      },
    ],
    features: [
      {
        icon: '🎂',
        name: 'Birthday and work anniversary messages',
        desc: (
          <div>
            Celebrate birthdays and work anniversaries of your coworkers.
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginTop: '14px',
                color: '#4f4f4f',
              }}
            >
              <LikeOutlined />
              &nbsp;Define custom events
            </div>
            <div style={{ marginTop: '12px' }}>
              <Link href="/billy/preview" style={{ fontSize: '16px' }}>
                {`>> Preview messages`}
              </Link>
            </div>
          </div>
        ),
      },
      {
        icon: '🎉',
        name: 'Fun & Customizable Copywriting',
        desc: 'Billy posts warm and fun messages. You can choose to include GIFs or to customize each celebration.',
      },
      {
        icon: '❎',
        name: 'Opt-out option',
        desc: "Anyone can opt-out from birthdays or work anniversaries if they don't want to be celebrated.",
      },
      {
        icon: '🔕',
        name: 'Week-end management',
        desc: (
          <div>
            When birthdays happen on week-ends, you can choose to delay the
            message on the next Monday or to celebrate on Friday.
            <div
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginTop: '14px',
                color: '#4f4f4f',
              }}
            >
              <LikeOutlined />
              &nbsp;Add manual public holidays
            </div>
          </div>
        ),
      },
      {
        icon: '📅',
        name: 'List of upcoming events',
        desc: "See next events and search for anyone's birthday.",
      },
      {
        icon: '🔄',
        name: 'Auto-collection of dates',
        desc: 'Billy can reach out to your colleagues to collect their birth dates.',
      },
      {
        icon: '📒',
        name: 'Spreadsheet bulk import',
        desc: "You can import people's dates inside a spreadsheet that we provide.",
      },
      {
        icon: '🔒',
        name: 'Private channels',
        desc: 'Billy can be added to any public or private channels.',
      },
      {
        icon: '⏰',
        name: 'Buddies reminders',
        desc: 'Be notified in advance for birthdays and work anniversaries.',
      },
    ],
    featuresPricing: [
      {
        name: 'Birthday messages',
      },
      {
        name: 'Work anniversary messages',
      },
      {
        name: 'List of upcoming events (with search)',
      },
      { name: 'Auto collection of dates' },
      { name: 'Spreadsheet import' },
      { name: 'Custom events' },
      {
        name: 'Opt-out option',
      },
      {
        name: 'Week-end management (Celebrate on Friday or Monday)',
      },
      {
        name: 'Private channels',
      },
      {
        name: 'Multi-channels',
      },
      {
        name: 'Reminders',
      },
      {
        name: 'Templates with GIFs',
      },
      {
        name: 'Customizable Messages',
      },
    ],
    additionnalResources: [
      {
        title: 'Reviews and testimonials of Billy birthday',
        name: 'Reviews',
        href: '/billy/reviews',
      },
      {
        title: 'Question about billy birthday',
        name: 'Frequently Asked Questions',
        href: '/billy/faq',
      },
      {
        title: 'Checkout our Free Birthday bot for Slack',
        name: 'Free Birthday bot for Slack',
        href: '/billy/free-birthday-bot-slack',
      },
      {
        title: 'Partner program Billy birthday',
        name: 'Partner program',
        href: 'https://partner.billybirthday.com/#/auth/register',
      },
    ],
    alternativeLinks: [
      {
        title: 'alternative slack birthday bot free',
        name: 'View all alternatives',
        href: '/billy/alternatives',
      },
      {
        title: 'Alternative to donut celebrations',
        name: 'Donut alternative',
        href: '/billy/alternatives/donut',
      },
      {
        title: 'Birthdaybot alternative',
        name: 'BirthdayBot alternative',
        href: '/billy/alternatives/birthdaybot',
      },
      {
        title: 'getairspeed alternative',
        name: 'Airspeed alternative',
        href: '/billy/alternatives/airspeed',
      },
    ],
  },
  {
    subscribeUrl: 'https://api.clappykudos.com/subscribe',
    subscribeDevUrl: 'https://api-dev.clappykudos.com/subscribe',
    price: 1,
    logoUrlWithBackground: '/img/apps/clappy/logo.png',
    href: 'https://buddieshr.com/clappy',
    localhref: '/clappy',
    logoUrl: '/img/apps/clappy/logo_without_background.png',
    color: '#bef4b2',
    lighterColor: '#bef4b2',
    fullName: 'Clappy Kudos',
    id: 'Clappy',
    slug: 'clappy',
    name: 'Clappy',
    lastName: 'Kudos',
    domain: 'clappykudos.com',
    verb: 'Recognize',
    title: 'Recognize your colleagues',
    shortTitle: 'Recognize employees, promote company values',
    shortTitleValue: 'Make sure good work is seen by everyone',
    shortDescription:
      'Promote peer recognition and broadcast your company values. Make everyone feel valued at work.',
    alt: 'Recognize employees, promote company values',
    videoUrl: '/img/apps/clappy-demo.mp4',
    successPageExtra: (
      <Tag
        icon={<BulbOutlined />}
        style={{
          marginTop: '12px',
          border: 'none',
          // fontWeight: "bold",
        }}
        color="blue"
      >
        Offering rewards boosts team engagement -{' '}
        <Link
          target="_blank"
          style={{ textDecoration: 'underline', color: '#0958d9' }}
          href="https://buddieshr.com/docs/clappy/admin-settings/rewards"
        >
          Learn more on how to setup rewards with Clappy
        </Link>
      </Tag>
    ),
    gradient:
      'linear-gradient(334deg,rgba(195,240,255,.69),rgba(193,245,236,.49))',
    textColorGradient:
      'linear-gradient(270deg, #4d9b51 10.33%, #3baab0 34.36%, #5baae8 60.15%, #39a778 84.04%, #31aa7c 92.04%, #39a779)',
    backgroundColor: '#f3fffa',
    installSlackLink: 'https://api.clappykudos.com/slack/install',
    // "https://clappy-api-prod.buddieshr.com/slack/install",
    competitors: [
      'heytaco',
      'matterbot',
      'bonusly',
      'shoutouts_by_airspeed',
      'evergreen',
      'engagewith',
      'kudosing',
      'kudosbot',
      'culturebot',
      'praisely',
      'karma',
      'dankon',
      'imfrank',
      'kudos_by_deel',
      'kudos3',
      'apprecibot',
      'hoyaapp',
    ],
    competitorAveragePrice: 2.9,
    show: true,
    tagColor: 'green',
    textColorBackground:
      'linear-gradient(270deg, #4d9b51 10.33%, #3baab0 34.36%, #5baae8 60.15%, #39a778 84.04%, #31aa7c 92.04%, #39a779)',
    benefits: [
      {
        icon: <LikeTwoTone />,
        title: 'Bring your company values to life',
        description:
          'Culture and values is the 1st satisfaction driver for employees. Easily tag your company’s values in the recognitions to promote and reinforce them organically.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Promote a culture of recognition',
        description:
          'Make your employees happier and more efficient thanks to regular feedback.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Employee engagement means performance and loyalty',
        description:
          'When employees feel valued, they stay in their jobs longer, strengthening company culture from the inside out.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Centralize recognition data for performance reviews',
        description:
          'Keep track of kudos and get tangible data for evaluating soft skills, good behaviors and how the employee embodies the company values for your performance reviews.',
      },
    ],
    getStartedSteps: [
      {
        icon: <LinkOutlined />,
        title: 'Connect your Slack workspace',
        description: (
          <>
            Click Add to Slack, you'll be ask to accept permissions for the app
            and Boom 💥 you're in! Don't worry, at this stage, you did not
            disturb anyone, the app is visible but did not generate any
            notifications. We will tell you when it does.
            <div style={{ marginTop: 12 }}>
              <Alert
                showIcon={true}
                type="info"
                message="You can install safely without disturbing anyone, we will inform you whenever other Slack users may be notified."
              />
            </div>
          </>
        ),
      },
      {
        icon: <ControlOutlined />,
        title: 'Setup channel and add employees',
        description: (
          <>
            Once Clappy is in your Slack, you can set up the channel where
            recognitions will be posted. To do this, go to Clappy's settings in
            Slack by clicking on its name in the left sidebard then make sure to
            be in the "home" tab.
          </>
        ),
      },
      {
        icon: <CheckSquareOutlined />,
        title: "You're all set!",
        description: (
          <>
            The best way to get started and launch the initiative is to start by
            sending kudos yourself.
          </>
        ),
      },
    ],
    features: [
      {
        icon: '👏',
        name: 'Peer-to-peer kudos',
        desc: 'Send public praise to recognize your colleagues.',
      },
      {
        icon: '⭐️',
        name: 'Company values in recognition',
        desc: 'Attach company values to the kudos.',
      },
      {
        icon: '🏆',
        name: 'Leaderboards',
        desc: 'Rankings to spot best performers for recognitions and per company value',
      },
      {
        icon: '🔄',
        name: 'Appreciation Day',
        desc: 'Boost usage with automated reminders on specific days',
      },
      {
        icon: '🎁',
        name: 'Rewards',
        desc: 'Give incentives and reward good behaviors',
      },
      {
        icon: '🕹️',
        name: 'Levels',
        desc: 'An extra gamification to push people to keep the habit of sending recognition regularly',
      },
    ],
    featuresPricing: [
      {
        name: 'Peer-to-peer kudos',
      },
      {
        name: 'Company values in recognition',
      },
      {
        name: 'Leaderboards',
      },
      {
        name: 'Appreciation Day',
      },
      {
        name: 'Reward system',
      },
      {
        name: 'Levels (gamification)',
      },
    ],
  },
  {
    subscribeUrl: 'https://api.pulsysurvey.com/subscribe',
    subscribeDevUrl: 'https://api-dev.pulsysurvey.com/subscribe',
    href: 'https://buddieshr.com/pulsy',
    price: 1,
    installSlackLink: 'https://api.pulsysurvey.com/slack/install',
    localhref: '/pulsy',
    pictureUrl: '/img/apps/product_screen_pulsy.png',
    logoUrl: '/img/apps/pulsy/logo_without_background.png',
    logoUrlWithBackground: '/img/apps/pulsy/logo.png',
    color: '#b4defe',
    lighterColor: '#b4defe',
    id: 'Pulsy',
    name: 'Pulsy',
    lastName: 'Survey',
    slug: 'pulsy',
    verb: 'Measure',
    domain: 'pulsysurvey.com',
    fullName: 'Pulsy Survey',
    shortTitle: 'Run polls, surveys, eNPS',
    shortTitleValue: 'Make sure everyone’s voice is heard',
    title: 'Listen to employees and measure engagement with surveys and polls.',
    shortDescription:
      "Measure eNPS and engagement. Listen to employee's voice. Broadcast surveys and get answers fast. Send polls in seconds.",
    alt: "listen to employee's voice and measure engagement",
    competitors: [
      'surveys',
      'surveyapp',
      'polly',
      'simplepoll',
      'pulse-surveys-roots',
    ],
    competitorAveragePrice: 1.6,
    show: true,
    tagColor: 'blue',
    backgroundColor: '#f5f9fc',
    textColorBackground:
      'linear-gradient(270deg, #33aeb3 10.33%, #056961 34.36%, #307dba 60.15%, #374fb9 84.04%, #3152aa 92.04%, #3173ad)',
    getStartedSteps: [
      {
        icon: <LinkOutlined />,
        title: 'Connect your Slack workspace',
        description: (
          <>
            Click Add to Slack, you'll be ask to accept permissions for the app
            and Boom 💥 you're in! Don't worry, at this stage, you did not
            disturb anyone, the app is visible but did not generate any
            notifications. We will tell you when it does.
            <div style={{ marginTop: 12 }}>
              <Alert
                showIcon={true}
                type="info"
                message="You can install safely without disturbing anyone, we will inform you whenever other Slack users may be notified."
              />
            </div>
          </>
        ),
      },
      {
        icon: <ControlOutlined />,
        title: 'Create your first survey or poll',
        description: (
          <>
            Once Pulsy is in your Slack, you can create your first survey or
            your first poll (just type <code>/poll</code>). For surveys, within
            a few clicks either choose a predefined template or select the
            questions you want.
            <div className={styles.productScreen}>
              <Image
                src="/img/apps/pulsy/product/screen_create_survey.png"
                width={409}
                height={281}
                alt="create a survey in slack"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              <Divider />
              <Image
                src="/img/apps/pulsy/product/poll-screen.png"
                width={501}
                height={317}
                alt="poll in slack"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
          </>
        ),
      },
      {
        icon: <CheckSquareOutlined />,
        title: "You're all set!",
        description: (
          <>
            Click Publish and you're all set! Pulsy will notify the employees in
            your Slack and get responses.
            <div className={styles.productScreen}>
              <Image
                src="/img/apps/pulsy/product/screen_publish.png"
                width={461}
                height={144}
                alt="create a survey in slack"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </div>
          </>
        ),
      },
    ],
    features: [
      {
        icon: '✅',
        name: 'Track eNPS and Employee engagement',
        desc: 'Measure and track eNPS and engagement over time.',
        isAnonymous: true,
      },
      {
        icon: '❓',
        name: 'Question bank and templates',
        desc: 'Access 58 questions in 12 themes. Build templates with the set of questions you choose.',
      },
      {
        icon: '📊',
        name: 'Run polls in seconds',
        desc: 'See results in real time, taking quick decisions is easy and crazy fast.',
      },
      {
        icon: '🔔',
        name: 'Smart & Automated Reminders',
        desc: 'Get a great completion rate thanks to our smart automated reminders for employees to answer the surveys.',
      },
      {
        icon: (
          <Image src="/img/slack_icon.png" alt="slack" width={50} height={50} />
        ),
        name: 'All in Slack',
        desc: 'No-one wants another app to register. This application is 100% in Slack.',
      },
      {
        icon: '💡',
        name: 'Powerful insights Dashboard',
        desc: 'Pulsy helps you analyse and visualize the results of the surveys and the trend over time.',
      },
      {
        isSuperPower: true,
        icon: '🪄',
        name: 'Access incomplete responses',
        desc: 'Access to responses even if they are incomplete and not yet submitted by the employee.',
      },
      {
        isSuperPower: true,
        icon: '💬',
        name: 'Anonymous conversations',
        desc: 'Ask clarifications on anonymous answers. Keep it private while getting more details on important feedbacks.',
      },
      {
        isComingSoon: true,
        icon: '👂',
        name: 'Continuous listening, monitoring, feedback',
        desc: 'Pulsy regularly sends questions taken from the engagement survey on various themes that will feed the insights.',
      },
    ],
    featuresPricing: [
      {
        name: 'Track eNPS',
      },
      {
        name: 'Track Employee engagement',
      },
      {
        name: 'Smart & Automated Reminders',
      },
      {
        name: 'Custom questions',
      },
      {
        name: 'Insights Dashboard',
      },
      {
        name: 'Access incomplete responses',
      },
      { name: 'Anonymous conversations' },
      // {
      //   name: "Continuous listening, monitoring, feedback",
      // },
    ],
    benefits: [
      {
        icon: <LikeTwoTone />,
        title: 'Know how employees feel, continuously',
        description:
          "The Employee Net Promoter Score is a must-have to understand your workforce's sentiment and get a measurable health check.",
      },
      {
        icon: <LikeTwoTone />,
        title: 'Surveys teams actually answer',
        description:
          'Get >90% response rate with our pulse surveys compared to 30% for traditional engagement surveys thanks to our smart notifications and our fluid user experience.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Automate your feedback loop and empower the HR teams',
        description:
          'Use pre-built teamplates to launch surveys in seconds, benefit from a frictionless UX, all-in-all the most efficient survey application out there.',
      },
      {
        icon: <LikeTwoTone />,
        title: 'Get actionable feedback to improve your culture',
        description:
          'Take action with a real-time understanding of employee engagement over specific thematics.',
      },
    ],
    additionnalResources: [
      {
        title: 'best slack poll app',
        name: 'Best Slack Poll and Survey App in 2024',
        href: '/pulsy/best-slack-poll-survey-app',
      },
      {
        title: 'best survey app for slack',
        name: 'Comparison Best survey App for Slack',
        href: '/alternatives/simplepoll-polly-pulsy',
      },
    ],
  },
  {
    subscribeUrl: 'https://linky-api-prod.buddieshr.com/subscribe',
    subscribeDevUrl: 'https://linky-api-prod.buddieshr.com/subscribe',
    show: true,
    href: 'https://buddieshr.com/linky',
    localhref: '/linky',
    price: 1,
    pictureUrl: '/img/apps/linky-demo.png',
    logoUrl: '/img/apps/linky/logo_without_background.png',
    logoUrlWithBackground: '/img/apps/linky/logo.png',
    // color: "#ffe0b8",
    color: '#acfff7',
    lighterColor: '#ccfffa',
    backgroundColor: '#f5fffd',
    gradient: 'linear-gradient(273deg, #d5f9f6, #9bebe3)',
    id: 'Linky',
    name: 'Linky',
    lastName: 'Directory',
    slug: 'linky',
    verb: 'Organize',
    // domain: "pulsysurvey.com",
    fullName: 'Linky Directory',
    shortTitle: 'Build your Org Chart & employee directory',
    shortTitleValue: 'Make it crystal clear who’s doing what',
    title: 'Org Chart and employee directory from your Slack workspace',
    shortDescription:
      'Build your org chart in seconds and keep it up-to-date automatically. Have a clear view on teams and department.',
    alt: 'Org Chart and employee directory from your Slack',
    competitors: ['organice'],
    competitorAveragePrice: 1.25,
    tagColor: 'cyan',
    installSlackLink: 'https://linky-api-prod.buddieshr.com/slack/install',
    textColorBackground:
      'linear-gradient(270deg, rgb(51, 174, 179) 10.33%, rgb(13 204 200) 34.36%, rgb(48, 125, 186) 60.15%, rgb(55, 179, 185) 84.04%, rgb(20, 69, 199) 92.04%, rgb(16 59 97))',
    featuresPricing: [
      {
        name: 'Team directory and org chart',
      },
      {
        name: 'Slack Command to search for someone',
      },
      {
        name: 'Auto-collection of information',
      },
      {
        name: 'Spreadsheet bulk import',
      },
    ],
    getStartedSteps: [
      {
        icon: <LinkOutlined />,
        title: 'Connect your Slack workspace',
        description: (
          <>
            Click Add to Slack, you'll be ask to accept permissions for the app
            and Boom 💥 you're in! Don't worry, at this stage, you did not
            disturb anyone, the app is visible but did not generate any
            notifications. We will tell you when it does.
            <div style={{ marginTop: 12 }}>
              <Alert
                showIcon={true}
                type="info"
                message="You can install safely without disturbing anyone, we will inform you whenever other Slack users may be notified."
              />
            </div>
          </>
        ),
      },
      {
        icon: <ControlOutlined />,
        title: 'Choose how to input the data (auto or manual)',
        description: (
          <>
            Once Linky is in your Slack, you can build your Org-Chart in seconds
            by either import the data from a spreadsheet (Linky provide the
            template) or choosing to put Linky on auto-pilot (employees will
            fill-in their manager)
          </>
        ),
      },
      {
        icon: <CheckSquareOutlined />,
        title: "You're all set!",
        description: (
          <>
            You can now see your org-chart being populated and you can visualize
            your team directory.
          </>
        ),
      },
    ],
    features: [
      {
        icon: '🗂️',
        name: 'Team directory and org chart',
        desc: 'Have a clear view on teams, departments and hierarchy.',
      },
      {
        icon: '👀',
        name: 'Slack Command to search for someone',
        desc: "Simply type /whois {username} to check someone's profile details.",
      },
      {
        icon: '🔄',
        name: 'Auto-collection of information',
        desc: 'Linky can reach out to the teams to collect their profile information and populate the org-chart.',
      },
      {
        icon: '📒',
        name: 'Spreadsheet bulk import',
        desc: "You can import people's data inside a spreadsheet that we provide.",
      },
    ],
    benefits: [
      {
        icon: <ApartmentOutlined />,
        title: 'Visualize your company structure and hierarchie',
        description:
          'Once your Org Chart completed, Linky gives you a full overview on the structuration.',
      },
      {
        icon: <DashboardOutlined />,
        title: 'Easily operate the performance reviews',
        description:
          'Thanks to an up-to-date Org Chart and employee directory, you can run the perf reviews smoothly.',
      },
      {
        icon: <SyncOutlined />,
        title: 'Always keep your Org Chart up-to-date',
        description:
          'We all know how traditionnal Org Chart end up... Outdated! Thanks to Linky, keep it up to date on auto pilot thanks to auto-collection of data and the slack integration',
      },
    ],
  },
  {
    subscribeUrl: 'https://stany-api-prod.buddieshr.com/subscribe',
    subscribeDevUrl: 'https://stany-api-prod.buddieshr.com/subscribe',
    show: true,
    href: 'https://buddieshr.com/stany',
    localhref: '/stany',
    price: 1,
    pictureUrl: '/img/apps/stany-demo.png',
    logoUrl: '/img/apps/stany/logo_without_background.png',
    logoUrlWithBackground: '/img/apps/stany/logo.png',
    // color: "#ffe0b8",
    color: '#acfff7',
    lighterColor: '#d5b9ff',
    backgroundColor: '#f3ebff',
    gradient: 'linear-gradient(273deg, rgb(218 198 248), rgb(223 230 255))',
    id: 'Stany',
    name: 'Stany',
    lastName: 'Standup',
    slug: 'stany',
    verb: 'Sync',
    // domain: "pulsysurvey.com",
    fullName: 'Stany Standup',
    shortTitle: 'Automate daily check-ins',
    shortTitleValue: 'Run standups, sync statuses and share blockers',
    title:
      'Asynchronously collect status updates directly in Slack and streamline the work of your remote team.',
    shortDescription:
      'Automate daily standups, check-ins, or any reports. Asynchronously collect status updates directly in Slack and streamline the work of your remote team.',
    alt: 'Daily asynchronous standup meetings in Slack',
    competitors: ['standuply', 'dailybot', 'geekbot'],
    competitorAveragePrice: 2.5,
    tagColor: 'purple',
    installSlackLink: 'https://stany-api-prod.buddieshr.com/slack/install',
    textColorBackground:
      'linear-gradient(270deg, rgb(85, 37, 140) 10.33%, rgb(5, 61, 105) 34.36%, rgb(48, 125, 186) 60.15%, rgb(161 55 185) 84.04%, rgb(107 34 200) 92.04%, rgb(37, 124, 157))',
    featuresPricing: [
      {
        name: 'Standups and daily checkins',
      },
      {
        name: 'Snooze for Today / until a certain date',
      },
      {
        name: 'Blockers summary',
      },
      {
        name: 'History of all standups',
      },
      {
        name: "Reminders (respecting user's timezones)",
      },
    ],
    getStartedSteps: [
      {
        icon: <LinkOutlined />,
        title: 'Connect your Slack workspace',
        description: (
          <>
            Click the "Add to Slack" button, you'll be ask to accept permissions
            for the app and Boom 💥 you're in! Don't worry, at this stage, you
            did not disturb anyone, the app is visible but did not generate any
            notifications. We will tell you when it does.
            <div style={{ marginTop: 12 }}>
              <Alert
                showIcon={true}
                type="info"
                message="You can install safely without disturbing anyone, we will inform you whenever other Slack users may be notified."
              />
            </div>
          </>
        ),
      },
      {
        icon: <ControlOutlined />,
        title: 'Choose where to post the standups and select participants',
        description: (
          <>
            Once Stany is in your Slack, you can create your first standup
            (Choose the channel where to post the standup, time and days). Then
            you can select the participants.
            <div>
              <Image
                style={{
                  marginTop: 12,
                  padding: 12,
                  borderRadius: 10,
                  background: 'white',
                  maxWidth: '100%',
                }}
                src="/img/apps/stany/setup-standup.png"
                alt="run standup in slack"
                width={1024 * 0.4}
                height={1146 * 0.4}
              />
            </div>
          </>
        ),
      },
      {
        icon: <CheckSquareOutlined />,
        title: "You're all set!",
        description: (
          <>
            You're ready for running your daily async standups in Slack.{' '}
            <div>
              <Image
                style={{
                  marginTop: 12,
                  padding: 12,
                  borderRadius: 10,
                  background: 'white',
                  height: 'auto',
                  maxWidth: '100%',
                }}
                src="/img/apps/stany/standup.png"
                alt="standup in slack"
                width={412}
                height={252}
              />
            </div>
          </>
        ),
      },
    ],
    features: [
      {
        icon: '🔄',
        name: 'Daily asynchronous standups',
        desc: 'Get a clear view on people statuses and blockers.',
      },
      {
        icon: '❌',
        name: 'Blockers Summary',
        desc: 'Help your team by identifying and fixing blockers easily',
      },
      {
        icon: '🔔',
        name: "Automated reminders (respecting user's timezones)",
        desc: 'Make sure people fill out the standup each day at the correct time for their timezone.',
      },
      {
        icon: '📣',
        name: 'Snooze',
        desc: 'Snooze the standup for the day or until a certain date.',
      },
      {
        icon: '✍️',
        name: 'Customize standup questions',
        desc: 'Edit the questions to match your perfect Standup needs.',
      },
    ],
    benefits: [
      {
        icon: <FieldTimeOutlined />,
        title: 'Free up hours of time for you and your team',
        description:
          "Say goodbye to time-consuming daily check-ins. Optimize your team's productivity with async stand-ups! Give your team more time for deep work. ",
      },
      {
        icon: <DeploymentUnitOutlined />,
        title: 'Keep your team aligned',
        description:
          'Improve team alignment by sharing daily statuses asynchronously. Putting it into writing makes it easy for people to come back to it.',
      },
      {
        icon: <CompassOutlined />,
        title: 'Stay flexible and make sure people are autonomous',
        description:
          'Remote teams remain flexible and autonomous by having a clear automated process.',
      },
      {
        icon: <FileSyncOutlined />,
        title: 'Push transparency',
        description:
          "It's easy for others to stay in the loop of what's happening in the team. It's also a good way to take a step back and have a big picture.",
      },
    ],
  },
  {
    subscribeUrl: 'https://ticky-api-prod.buddieshr.com/subscribe',
    subscribeDevUrl: 'https://ticky-api-prod.buddieshr.com/subscribe',
    show: true,
    href: 'https://buddieshr.com/ticky',
    localhref: '/ticky',
    price: 1,
    pictureUrl: '/img/apps/ticky-demo.png',
    logoUrl: '/img/apps/ticky/logo_without_background.png',
    logoUrlWithBackground: '/img/apps/ticky/logo.png',
    // color: "#ffe0b8",
    color: '#d5dadc',
    lighterColor: '#e8e8e8',
    backgroundColor: '#f3f3f3',
    gradient: 'linear-gradient(273deg, rgb(224 224 224), rgb(225 225 225))',
    id: 'Ticky',
    name: 'Ticky',
    lastName: 'Tracking',
    slug: 'ticky',
    verb: 'Track',
    // domain: "pulsysurvey.com",
    fullName: 'Ticky Tracking',
    shortTitle: 'Automated Time Tracking',
    shortTitleValue: 'Track Time Easily & Efficiently',
    title:
      'Automate time tracking in Slack and visualize project costs easily.',
    shortDescription:
      'Automate time tracking in Slack with reminders to make sure your team declares their working hours. Easily visualize project costs and profitability. ',
    alt: 'Time-tracking app for Slack',
    competitors: ['gryzzly', 'chronoscope', 'justworks'],
    competitorAveragePrice: 8,
    tagColor: 'grey',
    installSlackLink: 'https://ticky-api-prod.buddieshr.com/slack/install',
    textColorBackground:
      'linear-gradient(270deg, rgb(37 105 140) 10.33%, rgb(5 105 93) 34.36%, rgb(109 186 48) 60.15%, rgb(55 185 96) 84.04%, rgb(34 193 200) 92.04%, rgb(37, 124, 157))',
    featuresPricing: [
      {
        name: 'Time tracking per project in Slack',
      },
      {
        name: 'Reminders to employees',
      },
      {
        name: 'Track your R&D spend for reporting purposes (Tax Credits and Grants)',
      },
      {
        name: 'History logs',
      },
      {
        name: 'Capacity planning',
        comingSoon: true,
      },
      {
        name: 'Project costs and profitability',
        comingSoon: true,
      },
    ],
    getStartedSteps: [
      {
        icon: <LinkOutlined />,
        title: 'Connect your Slack workspace',
        description: (
          <>
            Click the "Add to Slack" button, you'll be ask to accept permissions
            for the app and Boom 💥 you're in!
            <div style={{ marginTop: 12 }}>
              <Alert
                showIcon={true}
                type="info"
                message="You can install safely without disturbing anyone, we will inform you whenever other Slack users may be notified."
              />
            </div>
          </>
        ),
      },
      {
        icon: <ControlOutlined />,
        title: 'Create a project and select employees who need to report',
        description: (
          <>
            Once Ticky is in your Slack, you can create your first project. Then
            you can select the participants.
            <div>
              <Image
                style={{
                  marginTop: 12,
                  height: 'auto',
                  maxWidth: '100%',
                }}
                src="/img/apps/ticky/screen1.png"
                alt="track time in slack"
                width={757 * 0.7}
                height={408 * 0.7}
              />
            </div>
          </>
        ),
      },
      {
        icon: <CheckSquareOutlined />,
        title: "You're all set!",
        description: (
          <>
            You're ready for collecting time reports from employees in Slack.{' '}
            {/* <div>
              <Image
                style={{
                  marginTop: 12,
                  padding: 12,
                  borderRadius: 10,
                  background: 'white',
                  height: 'auto',
                }}
                src="/img/apps/stany/standup.png"
                alt="standup in slack"
                width={412}
                height={252}
              />
            </div> */}
          </>
        ),
      },
    ],
    features: [
      {
        icon: '⏱️',
        name: 'Time tracking',
        desc: 'Track Time Easily & Efficiently by logging time directly in Slack within 1 click. Track projects, tasks, per day or hour.',
      },

      {
        icon: '🔔',
        name: 'Automated reminders',
        desc: 'Make sure people fill out their info. Choose the best granularity for reminders.',
      },
      {
        icon: '💰',
        name: 'Track your R&D spend ',
        desc: 'Great for reporting purposes (also for Tax Credits and Grants)',
      },
      {
        icon: '📋',
        name: 'History logs',
        desc: 'Get full access to all logs from the teams to audit past declaration or run reports.',
      },
      {
        icon: '🧮',
        name: 'Capacity planning',
        desc: 'Get a clear understanding on the needs of the business and plan the future with confidence.',
        comingSoon: true,
      },
      {
        icon: '💸',
        name: 'Track project cost and profitability',
        desc: 'Get access to an accurate data source to compute profitabilit and track project costs.',
        comingSoon: true,
      },
    ],
    benefits: [
      {
        icon: <FieldTimeOutlined />,
        title: 'Stop chasing your team to log their time',
        description:
          'Say goodbye to time-consuming reminders to your team. Ticky does that for you. Not only we\'ll remind employees to log their time but we\'ll make it easier with a 1-click log and features like "Same as yesterday".',
      },
      {
        icon: <SlackSquareOutlined />,
        title: 'Stay focus, Do it all in Slack',
        description:
          "Loging to another app to log time is the main reason people don't do it. By making it easily accessible in Slack, we ensure the team actually log their hours on time.",
      },
      {
        icon: <DollarOutlined />,
        title: 'Have a clear view on spend and costs',
        description:
          'The best way to have a clear view on project costs is to have accurate and Live data.',
      },
    ],
    show: false,
  },
];
